import { Col, Row, Button, Input, message, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../../constants";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { logOutUser } from "../../../common";
import AddGroups from "./AddGroups";
import EditGroups from "./EditGroups";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";


const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Groups = () => {
  const [credentialData, setCredentialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [isAddGrp, setIsAddGrp] = useState(false);
  const [isEditGrp, setIsEditGrp] = useState(false);
  const [selectedGrp, setSelectedGrp] = useState(null);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const [sortModel, setSortModel] = useState();
  const debouncedValue = useDebounce(search, 500);
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = credentialData
      .filter((credentialdata) => credentialdata.groupName.toLowerCase().includes(value.toLowerCase()))
      .map((credentialdata) => ({
        label: credentialdata.groupName,
        value: credentialdata.groupName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchGrp();
  }, [filter, debouncedValue, currentPage]);

  const fetchGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: search,
      },
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            text: grp.groupName,
            people: grp.users.length,
            application: grp.applications.length,
            selectedApps: grp.applications ? grp.applications.map((app) => app.id) : [],
            selectedUsers: grp.users ? grp.users.map((user) => user.id) : [],
            ...grp,
          }));
        }
        setCredentialData(Data);
        setTotalCount(res?.data?.totalGroups);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch Groups",
          });
        }
      });
  };
  const columns = [
    { field: "text", headerName: "Group Name", dataIndex: "text", flex: 1 },
    { field: "people", headerName: "Users", dataIndex: "people", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      dataIndex: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "edit",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedGrp(val.row);
                      setIsEditGrp(true);
                    }}
                  >
                    Edit
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Groups</h2>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setIsAddGrp(true)}>
                Add Groups
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>

                  <AutoComplete
                        placeholder="Search GroupName"
                        allowClear
                        onSearch={handleSearch}
                        options={options}
                        onChange={(value) => {
                          setSearch(value);
                        }}
                      />
                    {/* <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (currentPageModel.page !== 1) {
                          setCurrentPageModel({ page: 1, pageSize: 10 });
                        }
                      }}
                    /> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            rows={credentialData}
            columns={columns}
            style={{ border: "none" }}
            loading={isLoading}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPage}
            paginationModel={currentPageModel}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            onPaginationModelChange={(params) => {
              setCurrentPage(params.page);
            }}
          />
        </Col>
      </Row>
      {isAddGrp && (
        <AddGroups
          isAddGrp={isAddGrp}
          setIsAddGrp={setIsAddGrp}
          notification={notification}
          fetchGrp={fetchGrp}
        />
      )}
      {isEditGrp && (
        <EditGroups
          isEditGrp={isEditGrp}
          setIsEditGrp={setIsEditGrp}
          notification={notification}
          fetchGrp={fetchGrp}
          selectedGrp={selectedGrp}
        />
      )}
    </>
  );
};

export default Groups;
