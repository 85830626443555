import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Steps,
  Button,
  Input,
  Alert,
  Select,
  Form,
  Space,
  Tooltip,
  message,
  Typography,
} from "antd";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_PAM_URL,
} from "../../../constants";
import { DeleteOutlined, DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { logOutUser } from "../../../common";
import JSZip from "jszip";

const { Step } = Steps;
const { Option } = Select;
const ADStep = () => {
  const [current, setCurrent] = useState(0);
  const [orgUrl, setOrgUrl] = useState();
  const [formVal, setFormVal] = useState(null);
  const [selectPairs, setSelectPairs] = useState([{ select1: "Email", select2: "Username" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [adId, setAdId] = useState("");
  const [policyList, setPolicyList] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [sslConfigured, setSslConfigured] = useState("");
  const [formData, setFormData] = useState();
  const [adMfaMethod, setAdMfaMethod] = useState(null);
  const [adUsersList, setAdUsersList] = useState([]);

  const { Text } = Typography;

  useEffect(() => {
    fetchCredentialRotationPolicy();
  }, []);

  useEffect(() => {
    if (adId) {
      fetchAdUsers();
    }
  }, [adId]);

  const fetchAdUsers = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageNo: 1,
      pageSize: 100,
      filters: {
        emailId: "",
        status: "",
        logoname: "",
        adId: adId && adId,
      },
    };

    try {
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllAdUsers`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.users.map((user) => ({
        id: user?.id,
        label: user?.username,
        value: user?.id,
      }));

      setAdUsersList(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const createADMFAZipAndDownload = async () => {
    const zip = new JSZip();
    const url =
      "https://raw.githubusercontent.com/authnull0/windows-endpoint/ad-agent/authnull-install.ps1";

    // Add text content as a separate file to the ZIP
    zip.file("authnull-MFA.conf", textContent);

    // Fetch the file from the provided URL
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      const fileBlob = await response.blob();

      // Add the downloaded file to the ZIP
      zip.file("authnull-install.ps1", fileBlob);

      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a download link for the ZIP file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "authnull-install.zip";
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const createZipAndDownload = async () => {
    const zip = new JSZip();
    const url =
      "https://raw.githubusercontent.com/authnull0/windows-endpoint/ad-agent/ad-agent-install.ps1";

    // Add text content as a separate file to the ZIP
    zip.file("agent.conf", textContent);

    // Fetch the file from the provided URL
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      const fileBlob = await response.blob();

      // Add the downloaded file to the ZIP
      zip.file("ad-agent-install.ps1", fileBlob);

      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a download link for the ZIP file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "AD-agent-install.zip";
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const handleConnectADSubmit = (e) => {
    console.log(e);
    // const Domain = e?.dc?.split(",");
    const Domain = e?.domain;
    const dc1 = Domain.split(".")[0];
    const dc2 = Domain.split(".")[1];
    const payload = {
      ...e,
      dc1: dc1,
      dc2: dc2,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      status: "Pending",
      adAdminUserName: e?.userName,
    };
    setFormVal(payload);

    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/ad/AddDomain`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setAdId(res.data.id);
        setCurrent(current + 1);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };

  const fetchCredentialRotationPolicy = () => {
    let policyPayload = {
      pageId: 1,
      pageSize: 100,
      search: "",
      credentialType: "PASSWORD",
      filter: {
        filterBy: "userType",
        value: "LDAP",
      },
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, policyPayload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const newPolicyData = res?.data?.credentialPolicies?.map((data, index) => ({
          id: index + 1,
          label: data?.policyName,
          value: data?.policyId,
        }));
        setPolicyList(newPolicyData);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const textContent = `LDAP_SSL_PORT=${formVal?.SSLconfigured === "No" ? 0 : formVal?.Port}
LDAP_SERVER=${formVal?.adHost}
LDAP_PORT=389
LDAP_USERNAME=${formVal?.userName}
BASE_DN=${formVal?.dc}
DOMAIN=${formVal?.domain}
MachineName=${formVal?.directoryName}
#   time interval is 10 minutes
CRON_SCHEDULE= */10 * * * *
DomainId=${adId}
TenantId=${localStorage.getItem("DomainId")}
OrgId=${localStorage.getItem("OrgId")}
BaseURL=${REACT_APP_API}
policyId=${formVal?.credentialRotationPolicyId}
SERVICEACCOUNT_BASE_DN=${formVal?.serviceAccountDomain}
ADMFA=${adMfaMethod}
MFACatchingTime=60`;

  const downloadPamCode = () => {
    let fileName = "agent.conf";
    let fileContent = textContent;
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(fileContent)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const FirstContent = () => {
    const [selectedUsers, setSelectedUsers] = useState([]);

    const updateAdUsers = async () => {
      const payload = {
        tenantId: DOMAIN_ID(),
        orgId: ORG_ID(),
        adId: adId,
        userId: selectedUsers,
        mfaFlag: adMfaMethod,
      };

      try {
        const res = await axios.post(`${REACT_APP_API}/ad/UpdateMfaFlagADUsers`, payload, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        });

        navigate("/directory/activeDirectory");
        message.success("Users added successfully");
      } catch (error) {
        message.error("Failed to add users");
        console.log(error);
      }
    };

    return (
      <Row>
        <Col span={24}>
          <Row style={{ marginTop: "2rem" }}>
            <h3>Install AD Bridge</h3>
          </Row>
          <Row>
            <p>
              <b>Step 1: </b> Download the installation file and copy to target machine
            </p>
          </Row>
          <Row>
            <Col span={12}>
              <Button type="primary" onClick={createZipAndDownload}>
                Download <DownloadOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Row>
                <p>
                  <b>Step 2 : </b> In the target machine, open the PowerShell window as an
                  administrator privilege and execute the following command
                </p>
                <Input
                  style={{ border: "none", padding: "1rem" }}
                  size="large"
                  value=".\ad-agent-install.ps1 -OutputPath C:\authull"
                  disabled
                  suffix={
                    <Text
                      style={{ color: "GrayText" }}
                      copyable={{
                        text: ".ad-agent-install.ps1 -OutputPath C:authull",
                        tooltips: ["Copy Command", "Copied"],
                      }}
                    ></Text>
                  }
                />
              </Row>
            </Col>
          </Row>

          {adMfaMethod === 1 && (
            <>
              <Row style={{ marginTop: "2rem" }} gutter={20}>
                <Col span={12}>
                  <p>
                    Select Users{" "}
                    <Tooltip title="Select the users.">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                        }}
                      />
                    </Tooltip>{" "}
                  </p>
                  <Select
                    mode="multiple"
                    placeholder="Select users"
                    options={adUsersList}
                    onChange={(val) => {
                      console.log(val);
                      setSelectedUsers(val);
                    }}
                  />
                </Col>
              </Row>

              {/* <Row style={{ marginTop: "1rem" }}>
                <h3>Setup AD MFA</h3>
              </Row>

              <Row>
                <p>
                  <b>Step 1: </b> Download the AD MFA setup script and copy it to the target
                  machine.
                </p>
              </Row>
              <Row>
                <Col span={12}>
                  <Button type="primary" onClick={createADMFAZipAndDownload}>
                    Download <DownloadOutlined />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Row>
                    <p>
                      {" "}
                      <b>Step2 : </b> In the target machine, open the PowerShell window as an
                      administrator privilege and execute the following command.
                    </p>
                    <Input
                      style={{ border: "none", padding: "1rem" }}
                      size="large"
                      value="./authnull-install.ps1 -OutputPath C:\authull"
                      disabled
                      suffix={
                        <Text
                          style={{ color: "GrayText" }}
                          copyable={{
                            text: "./ad-agent-install.ps1 -OutputPath C:authull",
                            tooltips: ["Copy Command", "Copied"],
                          }}
                        ></Text>
                      }
                    />
                  </Row>
                </Col>
              </Row> */}
            </>
          )}

          <Row style={{ marginTop: "3rem" }} justify="space-between">
            <Col>
              <Button
                type="default"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  prev();
                }}
              >
                Prev
              </Button>
              <Button onClick={updateAdUsers} type="primary" style={{ fontSize: "16px" }}>
                Finish
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const SecondContent = () => {
    return (
      <Row>
        <Col span={20}>
          <Row style={{ marginTop: "2rem" }}>
            <h3>Connect to AD server</h3>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleConnectADSubmit}
                fields={formData}
                initialValues={{
                  SSLconfigured: "Yes",
                  Port: 636,
                }}
                onValuesChange={(changedValues) => {
                  if (changedValues.SSLconfigured !== undefined) {
                    form.setFieldsValue({
                      Port: changedValues.SSLconfigured === "Yes" ? 636 : 0,
                    });
                    setSslConfigured(changedValues);
                  }
                }}
              >
                <div>
                  <Row justify="space-between" align="middle">
                    <Col span={11}>
                      <Form.Item
                        name="directoryName"
                        label="Active Directory Name"
                        rules={[
                          {
                            required: true,
                            message: "AD Name is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="dc"
                        label={
                          <span>
                            Search Base for User&nbsp;
                            <Tooltip
                              title={
                                <span>
                                  Search Base for User must be ou=user,dc=example,dc=com format
                                </span>
                              }
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Search Base for User is required",
                          },
                          {
                            pattern:
                              /^((cn=[a-zA-Z0-9]+,|ou=[a-zA-Z0-9]+,)+dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message:
                              "Search Base for User must be ou=user,dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:ou=user,dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col span={11}>
                      <Form.Item
                        name="serviceAccountDomain"
                        label={
                          <span>
                            Search Base for Service Account&nbsp;
                            <Tooltip
                              title={
                                <span>
                                  Search Base for Service Account must be
                                  ou=serviceaccount,dc=example,dc=com format
                                </span>
                              }
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            pattern: /^(ou=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+,dc=[a-zA-Z0-9]+)$/,
                            message:
                              "Search Base for Service Account must be ou=serviceaccount,dc=example,dc=com format",
                          },
                        ]}
                      >
                        <Input placeholder="eg:ou=serviceaccount,dc=example,dc=com" />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="domain"
                        label={
                          <span>
                            Domain&nbsp;
                            <Tooltip title={<span>Domain must be "domain.com" format</span>}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Domain is required",
                          },
                          {
                            pattern: /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                            message: `Domain must be ${"domain.com"} format`,
                          },
                        ]}
                      >
                        <Input placeholder="eg:domain.com" />
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="adHost"
                        label="AD IP Address"
                        rules={[
                          {
                            required: true,
                            message: "AD IP is required",
                          },
                          {
                            pattern: /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,
                            message: "IP address range must be 0.0.0.0 to 255.255.255.255",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="userName"
                        label={
                          <span>
                            Username&nbsp;
                            <Tooltip title={<span>Admin Username</span>}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Username is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {/* <Col span={11}>
                      <Form.Item
                        name="password"
                        label={
                          <span>
                            Password&nbsp;
                            <Tooltip title={<span>Admin password is not stored anywhere </span>}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col> */}

                    <Col span={11}>
                      <Form.Item
                        name="credentialRotationPolicyId"
                        label="Credential Rotation Policy"
                        rules={[
                          {
                            required: true,
                            message: "Select a credential rotation policy",
                          },
                        ]}
                      >
                        <Select tokenSeparators={[","]} options={policyList} />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        label="SSL Configured"
                        name="SSLconfigured"
                        rules={[
                          {
                            required: true,
                            message: "Please select yes or no!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="adMfa"
                        label="Select AD MFA Method"
                        rules={[
                          {
                            required: true,
                            message: "Select AD MFA method",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select AD MFA method"
                          onChange={(value) => setAdMfaMethod(value)}
                        >
                          <Option value={1}>Agentless AD MFA</Option>
                          <Option value={0}>Agentbased AD MFA</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {form.getFieldValue("SSLconfigured") === "Yes" && (
                      <Col span={11}>
                        <Form.Item
                          name="Port"
                          label="Port"
                          rules={[
                            {
                              required: true,
                              message: "Port is required",
                            },
                          ]}
                        >
                          <Input placeholder="Enter port number" type="number" allowClear />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>
                <Row style={{ marginTop: "1rem" }} justify="space-between">
                  <Col></Col>
                  <Col>
                    <Button
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  //   return (
  //     <Row>
  //       {/* Your content for the second step */}
  //       <Col span={20}>
  //         <Row style={{ marginTop: "2rem" }}>
  //           <h3>Import Users</h3>
  //         </Row>
  //         <Row>
  //           <Col span={24}>
  //             <Row gutter={32}>
  //               <Col span={12}>
  //                 <Row>
  //                   <p className="search-label">Select Organizational Unit</p>
  //                 </Row>
  //                 <Row>
  //                   <Select style={{ width: "100%" }} showSearch placeholder="" labelInValue={true}>
  //                     <Option value="0">kloudone</Option>
  //                     <Option value="1">authnull</Option>
  //                     <Option value="2">Authnull</Option>
  //                   </Select>
  //                 </Row>
  //               </Col>
  //               <Col span={12}>
  //                 <Row>
  //                   <p className="search-label">DID Username Format (skip this if not necessary)</p>
  //                 </Row>
  //                 <Row>
  //                   <Select style={{ width: "100%" }} showSearch placeholder="" labelInValue={true}>
  //                     <Option value="0">Email Address</Option>
  //                     <Option value="1">SAM Account Name</Option>
  //                     <Option value="2">User Principal Name (UPN)</Option>
  //                   </Select>
  //                 </Row>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //         <Row style={{ marginTop: "1rem" }} justify="space-between">
  //           <Col></Col>
  //           <Col>
  //             <Button
  //               style={{ marginRight: "1rem" }}
  //               onClick={() => {
  //                 prev();
  //               }}
  //             >
  //               Prev
  //             </Button>
  //             <Button type="primary" onClick={() => next()}>
  //               Next
  //             </Button>
  //           </Col>
  //         </Row>
  //       </Col>
  //     </Row>
  //   );
  // };
  // const LastContent = () => {
  //   return (
  //     <Row>
  //       <Col span={20}>
  //         <Row style={{ marginTop: "2rem" }}>
  //           <h3>Map Fields: These fields are mapped from AD to Authnull</h3>
  //         </Row>
  //         <Row>
  //           <Col span={24}>
  //             {selectPairs.map((pair, index) => (
  //               <Row gutter={16} key={index} style={{ marginBottom: "8px" }}>
  //                 <Col span={11}>
  //                   <Select
  //                     value={pair.select1}
  //                     style={{ width: "100%" }}
  //                     onChange={(value) => handleSelect1Change(value, index)}
  //                   >
  //                     {options.map((option) => (
  //                       <Option key={option} value={option}>
  //                         {option}
  //                       </Option>
  //                     ))}
  //                   </Select>
  //                 </Col>
  //                 <Col span={11}>
  //                   <Select
  //                     value={pair.select2}
  //                     style={{ width: "100%" }}
  //                     onChange={(value) => handleSelect2Change(value, index)}
  //                   >
  //                     {options.map((option) => (
  //                       <Option key={option} value={option}>
  //                         {option}
  //                       </Option>
  //                     ))}
  //                   </Select>
  //                 </Col>
  //                 <Col span={2} style={{ textAlign: "center" }}>
  //                   {index > 0 && ( // Ensure there is at least one dropdown pair before showing delete icon
  //                     <DeleteOutlined
  //                       onClick={() => handleRemovePair(index)}
  //                       style={{
  //                         fontSize: "20px",
  //                         cursor: "pointer",
  //                         marginTop: "5px",
  //                       }}
  //                     />
  //                   )}
  //                 </Col>
  //               </Row>
  //             ))}
  //             <Row gutter={16} style={{ justifyContent: "space-between" }}>
  //               <Col span={6}>
  //                 <Button type="dashed" onClick={handleAddPair}>
  //                   Add More +
  //                 </Button>
  //               </Col>
  //               <Col span={6}>
  //                 <Button type="default" style={{ marginLeft: "1rem" }}>
  //                   Reconfigure
  //                 </Button>
  //               </Col>
  //             </Row>
  //             <Row style={{ marginTop: "1rem" }} justify="space-between">
  //               <Col></Col>
  //               <Col>
  //                 <Button
  //                   style={{ marginRight: "1rem" }}
  //                   onClick={() => {
  //                     prev();
  //                   }}
  //                 >
  //                   Prev
  //                 </Button>
  //                 <Button type="primary" onClick={() => next()}>
  //                   Next
  //                 </Button>
  //               </Col>
  //             </Row>
  //           </Col>
  //         </Row>
  //       </Col>
  //     </Row>
  //   );
  // };

  const steps = [
    {
      title: "Connect to AD servers",
      content: <SecondContent />,
    },
    {
      title: "Install AD Bridge",
      content: <FirstContent />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={20}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Integrate Active Directory</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Steps current={current} items={items} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>{steps[current].content}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ADStep;
