// import "./styles/index.scss";
import { useEffect, useState } from "react";
import { Avatar, Layout, Menu, Popover, Button, Row, Col, Typography } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import Users from "./directory/users/Users";
import { useLocation } from "react-router-dom";
import {
  ControlOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  RadarChartOutlined,
  SolutionOutlined,
  LogoutOutlined,
  IdcardOutlined,
  KeyOutlined,
  DesktopOutlined,
  SettingOutlined,
  RotateRightOutlined,
  PullRequestOutlined,
  CloudServerOutlined,
  FileProtectOutlined,
  ContainerOutlined,
  RadiusSettingOutlined,
  WindowsOutlined,
  PropertySafetyOutlined,
} from "@ant-design/icons/lib/icons";
import { UserOutlined } from "@ant-design/icons";
import { logOutUser } from "../common";
import Endpoints from "./endUser/Endpoints";
import MyConnections from "./endUser/MyConnections";
import AssignedVc from "./endUser/AssignedVc";
import Groups from "./directory/groups/Groups";
import Devices from "./directory/Devices";
import ActiveDirectory from "./directory/activeDirectory/ActiveDirectory";
import Jumpserver from "./privilegedAccessManagement/jumpServers/Jumpserver";
import AddJumpServer from "./privilegedAccessManagement/jumpServers/AddJumpServer";
import EndpointUsers from "./privilegedAccessManagement/endpointsUsers/EndpointUsers";
import SessionRecordings from "./privilegedAccessManagement/sessionRecordings/SessionRecordings";
import Connection from "./privilegedAccessManagement/connections/Connection";
import CreateConnection from "./privilegedAccessManagement/connections/CreateConnection";
import PAMEndpoint from "./privilegedAccessManagement/endpoints/Endpoints";
import CheckoutUser from "./privilegedAccessManagement/endpointsUsers/CheckoutUser";
import EndpointGroups from "./privilegedAccessManagement/endpointGroups/EndpointGroups";
import ViewCredentials from "./privilegedAccessManagement/endpointsUsers/ViewCredentials";
import { AddEndpoint } from "./privilegedAccessManagement/endpoints/AddEndpoint";
import CredentialRotationPolicy from "./privilegedAccessManagement/endpointsUsers/CredentialRotationPolicy";
import AddEndpointUser from "./privilegedAccessManagement/endpointsUsers/AddEndpointUser";
import ForceResetCredentials from "./privilegedAccessManagement/endpointsUsers/ForceResetCredentials";
import CredentialRotation from "./privilegedAccessManagement/credentialRotation/CredentialRotation";
import AddPasswordPolicy from "./privilegedAccessManagement/credentialRotation/AddPasswordPolicy";
import AddSshKeyPolicy from "./privilegedAccessManagement/credentialRotation/AddSshKeyPolicy";
import PasswordPolicies from "./privilegedAccessManagement/endpoints/PasswordPolicies";
import DidDashboard from "./did/dashboard";
import DIDS from "./did/dids";
import WalletUsers from "./did/walletUsers";
import Dashboard from "./dashboard";
import Credential from "./did/credentials";
import VerifiableCredential from "./did/verifiableCredential";
import TransactionLog from "./did/transactionLogs";
import EndpointGroupDetails from "./privilegedAccessManagement/endpointGroups/EndpointGroupDetails";
import EndpointDetails from "./privilegedAccessManagement/endpoints/EndpointDetails";
import AddHolderrDID from "./did/addHolderDID";
import AddUserDID from "./did/addUserDID";
import UserDetails from "./directory/UserDetails";
import UserDetailsGroups from "./directory/UserDetailsGroups";
import UserDetailsProfile from "./directory/UserDetailsProfile";
import EndpointUserDetails from "./privilegedAccessManagement/endpointsUsers/EndpointUserDetails";
import AddDirectory from "./directory/activeDirectory/addDirectory";
import ADStep from "./directory/activeDirectory/step";
import ServiceCredentials from "./serviceAccount/ServiceCredentials";
import AddServiceAccount from "./serviceAccount/AddServiceAccount";
import ServiceAccount from "./serviceAccount/ServiceAccount";
import AddWorkloadIdentity from "./workloadIdentity/AddworkloadIdentity";
import WorkloadIdentity from "./workloadIdentity/workloadIdentity";
import WorkloadConditionalAccess from "./workloadIdentity/WorkloadConditionalaccess";
import { REACT_APP_URL } from "../constants";
import WindowsEndpoints from "./windows/windowsPam";
import JitPolicy from "./windows/jitPolicy";
import JitConnections from "./windows/jitConnections";
import EndpointPolicies from "./windows/endpointPolicies";
import CreateWindowsPolicy from "./windows/createWindowsPolicy";
import ApplicationRequest from "./windows/applicationRequest";
import RequestLogs from "./windows/request";
import AuditLogs from "./windows/auditLogs";
import Settings from "./SuperAdmin/settings/Settings";
import AdminDashboard from "./SuperAdmin/Dashboard";
import Createtenant from "./SuperAdmin/CreateTenant";
import OktaSetup from "./SuperAdmin/settings/OktaSetup";
import Details from "./SuperAdmin/Details";
import Tenant from "./SuperAdmin/Tenant";
import AddTenant from "./SuperAdmin/AddTenant";
import AuthenticationPolicy from "./authenticationPolicy/request/Request";
import CreateEndpointAccessPolicy from "./authenticationPolicy/policies/CreatePolicy";
import CreateSimplifiedAccessPolicy from "./windows/simplifiedAccessPolicy";
import TenantConfig from "./windows/tenantConfig";
import Tour from "./tour";
import PolicyList from "./authenticationPolicy/policies/Policy";
import Module from "./onboarding/Module";
import Welcome from "./onboarding/Welcome";
import ConfigOnboard from "./onboarding/ConfigOnboard";
import RiskBasedMFA from "./onboarding/setups/RiskBasedMFA";
import PamServiceAccount from "./onboarding/setups/PamServiceAccount";
import PamLocalAccount from "./onboarding/setups/PamLocalAccount";
import Splunk from "./splunk/Splunk";
import Logs from "./authenticationPolicy/logs";
import { OnboardLinuxV2 } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardLinuxV2";
import { OnboardLinux } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardLinux";
import { OnboardWindow } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardWindow";
import { OnboardWindowV2 } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardWindowV2";
import CreatePolicyV2 from "./authenticationPolicy/policies/CreatePolicyV2";
import SecretsList from "./secretsManagement/SecretsList";
import CreatePolicyV3 from "./authenticationPolicy/policies/CreatePolicyV3";
import Policy from "./endUser/Policy";
import Request from "./endUser/Request";
import AddSecret from "./secretsManagement/AddSecret";
import AdUser from "./directory/users/AdUser";
import { UseLogout } from "../common/UseLogout";
import Logout from "./unauthorized/Logout";
import AgentLogs from "./privilegedAccessManagement/endpoints/AgentLogs";
import EndpointRules from "./dit/endpointsRules/endpointRules";
import CreatePolicyDIT from "./dit/createPolicy/createPolicy";
import AllAgentLogs from "./agentLogs/AllLogs";
import EndpointRulesLogs from "./dit/endpointsRules/logs/logs";
import EndpointRulesRequests from "./dit/endpointsRules/requests/requests";
import EndpointRulesPolicies from "./dit/endpointsRules/policies/policies";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
function PrimaryLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole] = useState(localStorage.getItem("UserRole"));
  const [userName] = useState(localStorage.getItem("UserName"));
  const [openKeys, setOpenKeys] = useState([]);
  const [openSubMenuKeys, setOpenSubMenuKeys] = useState([]);
  const [mode, setMode] = useState(localStorage.getItem("currentMode") || "pam");
  const [mainMenuSelectedValues, setMainMenuSelectedValues] = useState([]);
  const currentPath = location.pathname;
  const ENVIRONMENT = process.env.NODE_ENV;

  const logOutUser = UseLogout();

  const CurrentMode = () => {
    localStorage.setItem("currentMode", "pam");
    const path = currentPath?.split("/")[1];
    if (path) {
      setMode(path);
      localStorage.setItem("currentMode", path); // Store the initial mode in localStorage
    } else {
      setMode("pam");
    }
  };

  useEffect(() => {
    CurrentMode();
    handleSubMenuOpenChange([]);
    getCurrentSelectedKey([]);
  }, [mode]);

  const getCurrentSelectedKey = (keys) => {
    const path = currentPath.split("/");

    if (keys?.key) {
      setMainMenuSelectedValues([keys.key]);
    } else if (keys.key == "") {
      setMainMenuSelectedValues([""]);
    } else if (path.length === 2) {
      setMainMenuSelectedValues([path[1]]);
    } else {
      setMainMenuSelectedValues([`${path[1]}/${path[2]}`]); // used to select Active sidebar menu
    }
  };

  const handleNavigation = (path) => {
    if (localStorage.getItem("token")) {
      navigate(path);
    } else {
      logOutUser();
    }
  };
  const handleSubMenuOpenChange = (keys) => {
    // Ensure only one submenu is open at a time
    if (keys.length > 1) {
      setOpenKeys([keys.pop()]); // Keep the last opened submenu
      setOpenSubMenuKeys([]);
    } else {
      setOpenKeys(
        mode === "pam"
          ? currentPath.includes("/pam/endpointsUsers") ||
            currentPath.includes("/pam/endpointGroups") ||
            currentPath.includes("/pam/endpoint")
            ? ["endpoint"]
            : currentPath.includes("jumpServer")
            ? ["jumpServer"]
            : currentPath.includes("policy")
            ? ["authPolicies"]
            : [""]
          : mode === "directory"
          ? currentPath.includes("did")
            ? ["did"]
            : ["directory"]
          : mode === "dit"
          ? currentPath.includes("dit")
            ? ["dit"]
            : [""]
          : mode === "window" && ["window"]
      );
    }
  };
  // my chnages
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleOptionSelect = () => {
    // Update the state and close the Popover
    setPopoverVisible(false);
  };
  // ends here

  const section = (
    <Row gutter={[16, 16]} align="middle" style={{ width: "305px", marginTop: "-20px" }}>
      <Col span={24}>
        <Row
          gutter={16}
          align="middle"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: "-12px",
            marginRight: "-12px",
            background: "#000",
          }}
        >
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>
          <Col>
            <Text style={{ color: "#fff" }} strong>
              {localStorage.getItem("UserName")}
              {userRole !== "SUPERADMIN" && "(" + localStorage.getItem("Tenantname") + ")"}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{
            borderRight: 0,
          }}
        >
          {userRole === "ADMIN" && (
            <>
              <Menu.Item
                icon={<ControlOutlined />}
                key="pam"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/pam/endpointsUsers");
                  setMode("pam");
                }}
                style={{ padding: "16px" }}
              >
                PAM
              </Menu.Item>
              <Menu.Item
                icon={<PropertySafetyOutlined />}
                key="dit"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/dit/endpointRules/requests");
                  setMode("dit");
                }}
                style={{ padding: "16px" }}
              >
                DIT
              </Menu.Item>
              <Menu.Item
                icon={<SolutionOutlined />}
                key="directory"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/directory/users");
                  setMode("directory");
                }}
                style={{ padding: "16px" }}
              >
                Directory
              </Menu.Item>
              <Menu.Item
                icon={<RadiusSettingOutlined />}
                key="Config"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/tenantConfig");
                  setMode("tenantConfig");
                }}
                style={{ padding: "16px" }}
              >
                Tenant Config
              </Menu.Item>
              {(REACT_APP_URL === "https://localhost:5173/" || ENVIRONMENT === "testing") && (
                <>
                  <Menu.Item
                    icon={<WindowsOutlined />}
                    key="windows"
                    onClick={() => {
                      handleOptionSelect();
                      handleNavigation("/windows/endpoints");
                      setMode("window");
                    }}
                    style={{ padding: "16px" }}
                  >
                    Windows
                  </Menu.Item>
                </>
              )}
            </>
          )}
          <Menu.Item
            icon={<LogoutOutlined />}
            key="logout"
            onClick={() => {
              logOutUser();
            }}
            style={{ color: "blue", padding: "16px" }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );

  const withoutHeaderPage = [
    "/onboard",
    "/onboard/module",
    "/logout",
    // "/onboard/config",
    // "/onboard/setup/azureAdUser",
    // "/onboard/setup/mfa",
    // "/onboard/setup/localAccount",
    // "/onboard/setup/tenantConfig",
  ];

  return (
    <>
      <Layout style={{ minHeight: "100vh" }} className="app-container">
        {!withoutHeaderPage.includes(currentPath) && (
          <Header className="header-container">
            <h1 className="logo-container">
              <RadarChartOutlined />
              Authnull
            </h1>

            {/* <Popover
            placement="bottom"
            title={localStorage.getItem("UserName")}
            content={
              <a onClick={() => logOutUser()}>
                <LogoutOutlined /> Log Out
              </a>
            }
          >
            <Avatar size="large" style={{ background: "#fff", color: "#000" }}>
              {userName[0].toLocaleUpperCase()}
            </Avatar>
          </Popover> */}
          </Header>
        )}

        <Layout className="body-container" style={{ flex: 1 }} hasSider>
          {!withoutHeaderPage.includes(currentPath) && (
            <Sider width={280} className="left-menu-container">
              <div className="sidebar__container">
                <div className="sidebar__container__menu">
                  <Menu
                    mode="inline"
                    // defaultSelectedKeys={["1"]}
                    openKeys={openKeys} // Set the openKeys to maintain open submenus
                    onOpenChange={handleSubMenuOpenChange} // Handle submenu open changes
                    style={{
                      borderRight: 0,
                    }}
                    selectedKeys={mainMenuSelectedValues}
                    onClick={getCurrentSelectedKey}
                  >
                    {userRole === "ADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key=""
                          onClick={() => handleNavigation("/")}
                        >
                          Dashboard
                        </Menu.Item>
                        {/* TODO: TOUR IS UNDER DEVELOPMENT */}
                        {ENVIRONMENT === "development" && (
                          <Menu.Item
                            icon={<DashboardOutlined />}
                            key="onboard/config"
                            onClick={() => handleNavigation("/onboard/config")}
                          >
                            Getting Started
                          </Menu.Item>
                        )}
                        {mode === "pam" && (
                          <>
                            <SubMenu key="endpoint" title="Endpoint" icon={<ControlOutlined />}>
                              <Menu.Item
                                key="pam/endpointsUsers"
                                onClick={() => handleNavigation("/pam/endpointsUsers")}
                              >
                                Endpoints Users
                              </Menu.Item>
                              <Menu.Item
                                key="pam/endpointGroups"
                                onClick={() => handleNavigation("/pam/endpointGroups")}
                              >
                                Endpoints Groups
                              </Menu.Item>
                              <Menu.Item
                                key="pam/endpoint"
                                onClick={() => handleNavigation("/pam/endpoint")}
                              >
                                Endpoints
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu
                              key="jumpServer"
                              title="Jump Server"
                              icon={<CloudServerOutlined />}
                            >
                              <Menu.Item
                                key="pam/jumpServer"
                                onClick={() => handleNavigation("/pam/jumpServer")}
                              >
                                Jump Servers
                              </Menu.Item>
                              <Menu.Item
                                key="pam/jumpServer-connections"
                                onClick={() => handleNavigation("/pam/jumpServer-connections")}
                              >
                                Connections
                              </Menu.Item>
                              <Menu.Item
                                key="pam/jumpServer-sessionRecordings"
                                onClick={() =>
                                  handleNavigation("/pam/jumpServer-sessionRecordings")
                                }
                              >
                                Session Recording
                              </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                              key="pam/credentialRotation"
                              icon={<RotateRightOutlined />}
                              onClick={() => handleNavigation("/pam/credentialRotation")}
                            >
                              Credential Rotation
                            </Menu.Item>

                            <SubMenu
                              key="authPolicies"
                              icon={<PullRequestOutlined />}
                              title="Authentication Policies"
                            >
                              <Menu.Item
                                key="pam/policy-request"
                                onClick={() => handleNavigation("pam/policy-request")}
                              >
                                Requests
                              </Menu.Item>
                              <Menu.Item
                                key="pam/policy"
                                onClick={() => handleNavigation("/pam/policy")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="pam/policy-logs"
                                onClick={() => handleNavigation("/pam/policy-logs")}
                              >
                                Authentication Logs
                              </Menu.Item>
                            </SubMenu>
                            {/* <SubMenu   NOTE: SERVICE ACCOUNT NOT NEED NOW 
                              key="serviceAccount"
                              icon={<CreditCardOutlined />}
                              title="Service Account"
                            >
                              <Menu.Item
                                key="showServiceAccount"
                                onClick={() => handleNavigation("/serviceCredentials")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="listServiceAccount"
                                onClick={() => handleNavigation("/serviceAccount")}
                              >
                                Service Account
                              </Menu.Item>
                            </SubMenu> */}
                            <Menu.Item
                              key="pam/secrets"
                              icon={<FileProtectOutlined />}
                              onClick={() => handleNavigation("/pam/secrets")}
                            >
                              Secrets Management
                            </Menu.Item>
                            <Menu.Item
                              key="logs"
                              icon={<FileProtectOutlined />}
                              onClick={() => handleNavigation("/logs")}
                            >
                              Logs Management
                            </Menu.Item>
                          </>
                        )}
                        {mode === "directory" && (
                          <>
                            <SubMenu key="directory" icon={<SolutionOutlined />} title="Directory">
                              <Menu.Item
                                key="directory/users"
                                onClick={() => handleNavigation("/directory/users")}
                              >
                                Users
                              </Menu.Item>
                              <Menu.Item
                                key="directory/groups"
                                onClick={() => handleNavigation("/directory/groups")}
                              >
                                Groups
                              </Menu.Item>
                              {/* <Menu.Item key="devices" onClick={() => handleNavigation("/directory/devices")}>
                    Devices
                  </Menu.Item> */}
                              <Menu.Item
                                key="directory/activeDirectory"
                                onClick={() => handleNavigation("/directory/activeDirectory")}
                              >
                                Active Directory
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu key="did" icon={<CreditCardOutlined />} title="DID">
                              <Menu.Item
                                key="directory/did-dashboard"
                                onClick={() => handleNavigation("/directory/did-dashboard")}
                              >
                                Dashboard
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did"
                                onClick={() => handleNavigation("/directory/did")}
                              >
                                DIDs
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-walletUser"
                                onClick={() => handleNavigation("/directory/did-walletUser")}
                              >
                                Wallet Users
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-verifiableCredentials"
                                onClick={() =>
                                  handleNavigation("/directory/did-verifiableCredentials")
                                }
                              >
                                Verifiable Credentials
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-transactionLogs"
                                onClick={() => handleNavigation("/directory/did-transactionLogs")}
                              >
                                Transaction Logs
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}

                        {mode === "dit" && (
                          <>
                            <SubMenu
                              key="endpointRules"
                              icon={<PullRequestOutlined />}
                              title="Endpoint Rules"
                            >
                              <Menu.Item
                                key="dit/endpointRequests"
                                onClick={() => handleNavigation("/dit/endpointRules/requests")}
                              >
                                Requests
                              </Menu.Item>
                              <Menu.Item
                                key="dit/endpointPolicies"
                                onClick={() => handleNavigation("/dit/endpointRules/policies")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="endpointRules/logs"
                                onClick={() => handleNavigation("/dit/endpointRules/logs")}
                              >
                                Authentication Logs
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}

                        {(REACT_APP_URL === "https://localhost:5173/" ||
                          ENVIRONMENT === "testing") &&
                          mode === "window" && (
                            <SubMenu key="windows" icon={<SolutionOutlined />} title="Windows EPS">
                              <Menu.Item
                                key="windowsEndpoints"
                                onClick={() => handleNavigation("/windows/endpoints")}
                              >
                                Windows Endpoints
                              </Menu.Item>

                              <Menu.Item
                                key="endpointPolicies"
                                onClick={() => handleNavigation("/windows/endpointPolicies")}
                              >
                                Endpoint Policies
                              </Menu.Item>

                              <Menu.Item
                                key="requestLogs"
                                onClick={() => handleNavigation("/windows/requestLogs")}
                              >
                                Request Logs
                              </Menu.Item>
                              <Menu.Item
                                key="auditLogs"
                                onClick={() => handleNavigation("/windows/auditLogs")}
                              >
                                Audit Logs
                              </Menu.Item>
                            </SubMenu>
                          )}

                        {REACT_APP_URL === "http://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "dpa" && (
                            <SubMenu key="dfa" icon={<SolutionOutlined />} title="DPA PAM">
                              <Menu.Item
                                key="windowsJit"
                                onClick={() => handleNavigation("/windows/jit")}
                              >
                                JIT Access Policies
                              </Menu.Item>
                              <Menu.Item
                                key="windowsJitConnection"
                                onClick={() => handleNavigation("/windows/jitConnections")}
                              >
                                JIT Connections
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {REACT_APP_URL === "https://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "pam" && (
                            <SubMenu
                              key="workload"
                              icon={<IdcardOutlined />}
                              title="Workload Identity"
                            >
                              <Menu.Item
                                key="workloadIdentity"
                                onClick={() => handleNavigation("/workloadIdentity")}
                              >
                                Workload Identity
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {mode === "tenantConfig" && (
                          <Menu.Item
                            key="tenantConfig"
                            icon={<RadiusSettingOutlined />}
                            onClick={() => handleNavigation("/tenantConfig")}
                          >
                            Tenant Config
                          </Menu.Item>
                        )}
                      </>
                    ) : userRole === "SUPERADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="org/dashboard"
                          onClick={() => handleNavigation("/org/dashboard")}
                        >
                          Dashboard
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="tenant"
                          onClick={() => handleNavigation("/tenant")}
                        >
                          Tenant
                        </Menu.Item>
                        <Menu.Item
                          icon={<SettingOutlined />}
                          key="setting"
                          onClick={() => handleNavigation("/setting")}
                        >
                          Settings
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          icon={<DesktopOutlined />}
                          key="endUser/endpoints"
                          onClick={() => handleNavigation("/endUser/endpoints")}
                        >
                          Endpoints
                        </Menu.Item>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="endUser/connections"
                          onClick={() => handleNavigation("/endUser/connections")}
                        >
                          Connections
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="endUser/assignedVcs"
                          onClick={() => handleNavigation("/endUser/assignedVcs")}
                        >
                          Assigned VC's
                        </Menu.Item>
                        <Menu.Item
                          key="endUser/request"
                          icon={<PullRequestOutlined />}
                          onClick={() => handleNavigation("/endUser/request")}
                        >
                          Requests
                        </Menu.Item>
                        <Menu.Item
                          key="endUser/policies"
                          icon={<ContainerOutlined />}
                          onClick={() => handleNavigation("/endUser/policies")}
                        >
                          Policies
                        </Menu.Item>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="sidebar__container__footer">
                  <Popover
                    placement="topRight"
                    content={section}
                    trigger="click"
                    visible={popoverVisible}
                    onVisibleChange={handlePopoverVisibleChange}
                  >
                    <Button
                      type="text"
                      icon={
                        mode === "pam" ? (
                          <ControlOutlined />
                        ) : mode === "directory" ? (
                          <SolutionOutlined />
                        ) : mode === "window" ? (
                          <WindowsOutlined />
                        ) : mode === "tenantConfig" ? (
                          <RadiusSettingOutlined />
                        ) : mode === "dit" ? (
                          <PropertySafetyOutlined />
                        ) : (
                          <SettingOutlined />
                        )
                      }
                      onClick={() => setPopoverVisible(!popoverVisible)}
                      style={{
                        color: "#1890ff",
                        paddingLeft: "24px",
                      }}
                    >
                      {userRole === "ADMIN" && mode
                        ? mode === "pam"
                          ? "PAM"
                          : mode === "dit"
                          ? "DIT"
                          : mode === "window"
                          ? "Windows"
                          : mode === "directory"
                          ? "Directory"
                          : mode === "tenantConfig"
                          ? "Tenant Config"
                          : "Switch"
                        : "Switch"}
                    </Button>
                  </Popover>
                </div>
              </div>
            </Sider>
          )}
          <Content
            className={
              withoutHeaderPage.includes(currentPath)
                ? "without-header page-container"
                : "with-header page-container"
            }
          >
            <Routes>
              <Route path="logout" element={<Logout />} />
              {userRole === "ADMIN" ? (
                <>
                  <Route path="" element={<Dashboard />} />
                  <Route path="tour" element={<Tour />} />
                  <Route path="pam/*">
                    <Route path="jumpServer/*">
                      <Route path="" element={<Jumpserver />} />
                      <Route path="add" element={<AddJumpServer />} />
                    </Route>
                    <Route path="jumpServer-connections/*">
                      <Route path="" element={<Connection />} />
                      <Route path=":publicIpAddress" element={<Connection />} />
                      <Route path="createConnection" element={<CreateConnection />} />
                    </Route>
                    <Route path="jumpServer-sessionRecordings/*">
                      <Route path="" element={<SessionRecordings />} />
                    </Route>
                    <Route path="endpoint/*">
                      <Route path="" element={<PAMEndpoint />} />
                      <Route path="addEndpoint/*">
                        <Route path="linux" element={<OnboardLinuxV2 />} />
                        <Route path="windows" element={<OnboardWindowV2 />} />
                      </Route>
                      <Route path="endpointDetails" element={<EndpointDetails />} />
                      <Route path="passwordPolicies" element={<PasswordPolicies />} />
                      <Route path="agentLogs" element={<AgentLogs />} />
                    </Route>

                    <Route path="endpointsUsers/add" element={<AddEndpointUser />} />
                    <Route path="endpointsUsers" element={<EndpointUsers />}></Route>
                    <Route path="endpointuserDetails" element={<EndpointUserDetails />} />

                    <Route path="endpointsUsers/checkoutUser" element={<CheckoutUser />} />
                    <Route
                      path="rotation/:instanceId/:epmUserId"
                      element={<CredentialRotationPolicy />}
                    />
                    <Route
                      path="endpointsUsers/reset-credentials"
                      element={<ForceResetCredentials />}
                    />
                    <Route
                      path="view-credentials/:instanceId/:epmUserId"
                      element={<ViewCredentials />}
                    />
                    <Route path="endpointGroups/*">
                      <Route path="" element={<EndpointGroups />} />
                      <Route path="endpointGroupDetails" element={<EndpointGroupDetails />} />
                    </Route>
                    <Route path="sessionRecordings/:id" element={<SessionRecordings />} />

                    <Route path="credentialRotation/*">
                      <Route path="" element={<CredentialRotation />} />
                      <Route path="addPassword" element={<AddPasswordPolicy />} />
                      <Route path="addSshKeys" element={<AddSshKeyPolicy />} />
                    </Route>

                    <Route path="policy/*">
                      <Route path="" element={<PolicyList />} />
                      <Route path="createEndpointAccessPolicy" element={<CreatePolicyV3 />} />
                    </Route>
                    <Route path="policy-request" element={<AuthenticationPolicy />} />
                    <Route path="policy-logs" element={<Logs />} />

                    <Route path="secrets/*">
                      <Route path="" element={<SecretsList />} />
                      <Route path="add" element={<AddSecret />} />
                    </Route>
                  </Route>

                  <Route path="windows/*">
                    <Route path="endpoints" element={<WindowsEndpoints />} />
                    <Route path="jit" element={<JitPolicy />} />
                    <Route path="jitConnections" element={<JitConnections />} />
                    <Route path="createWindowPolicy" element={<CreateWindowsPolicy />} />

                    <Route
                      path="createSimplifiedAccessPolicy"
                      element={<CreateSimplifiedAccessPolicy />}
                    />
                    <Route path="tenantConfig" element={<TenantConfig />} />
                    <Route path="applicationRequest" element={<ApplicationRequest />} />
                    <Route path="auditLogs" element={<AuditLogs />} />
                    <Route path="endpointPolicies" element={<EndpointPolicies />} />
                    <Route path="requestLogs" element={<RequestLogs />} />

                    {/* <Route path="endpoints" element={<AddJumpServer />} /> */}
                  </Route>

                  <Route path="directory/*">
                    {/* <Route path="users" element={<Users />} /> NOTE:OLD Directory USER SCREEN  */}
                    <Route path="users" element={<AdUser />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="devices" element={<Devices />} />
                    <Route path="activeDirectory/addDirectory/step" element={<ADStep />} />
                    <Route path="activeDirectory/addDirectory" element={<AddDirectory />} />
                    <Route path="activeDirectory" element={<ActiveDirectory />} />
                    <Route path="userDetails" element={<UserDetails />} />
                    <Route path="userDetailsGroups" element={<UserDetailsGroups />} />
                    <Route path="userDetailsProfile" element={<UserDetailsProfile />} />
                    <Route path="did/*">
                      <Route path="" element={<DIDS />} />
                      <Route path="addIssuerDID" element={<AddUserDID />} />
                      <Route path="addHolderDID" element={<AddHolderrDID />} />
                    </Route>
                    <Route path="did-dashboard" element={<DidDashboard />} />
                    <Route path="did-walletUser" element={<WalletUsers />} />
                    <Route path="did-walletUser/view-credentials" element={<Credential />} />
                    <Route path="did-verifiableCredentials" element={<VerifiableCredential />} />
                    <Route path="did-transactionLogs" element={<TransactionLog />} />
                  </Route>
                  <Route path="tenantConfig" element={<TenantConfig />} />

                  <Route path="serviceAccount/*">
                    <Route path="" element={<ServiceAccount />} />
                    <Route path="addServiceAccount" element={<AddServiceAccount />} />
                  </Route>
                  <Route path="serviceCredentials" element={<ServiceCredentials />} />
                  <Route path="workloadIdentity/*">
                    <Route path="" element={<WorkloadIdentity />} />
                    <Route path="addWorkloadIdentity" element={<AddWorkloadIdentity />} />
                    <Route
                      path="workloadConditionalaccess"
                      element={<WorkloadConditionalAccess />}
                    />
                  </Route>
                  <Route path="onboard/*">
                    <Route path="" element={<Welcome />} />
                    <Route path="module" element={<Module />} />
                    <Route path="config" element={<ConfigOnboard />} />
                    <Route path="setup/*">
                      <Route path="mfa" element={<RiskBasedMFA />} />
                      <Route path="serviceAccount" element={<PamServiceAccount />} />
                      <Route path="localAccount" element={<PamLocalAccount />} />
                      <Route path="tenantConfig" element={<TenantConfig />} />
                    </Route>
                  </Route>
                  <Route path="splunk" element={<Splunk />} />

                  <Route path="dit/*">
                    <Route path="endpointRules" element={<EndpointRules />} />
                    <Route path="createPolicy" element={<CreatePolicyDIT />} />
                    <Route path="endpointRules/logs" element={<EndpointRulesLogs />} />
                    <Route path="endpointRules/requests" element={<EndpointRulesRequests />} />
                    <Route path="endpointRules/policies" element={<EndpointRulesPolicies />} />
                  </Route>
                  <Route path="logs" element={<AllAgentLogs />} />
                </>
              ) : userRole === "SUPERADMIN" ? (
                <>
                  <Route path="tenant/*">
                    <Route path="" element={<Tenant />} />
                    {/* <Route path="add" element={<Createtenant />} /> */}
                    <Route path="add" element={<AddTenant />} />
                  </Route>
                  <Route path="setting/*">
                    <Route path="" element={<Settings />} />
                    <Route path="okta" element={<OktaSetup />} />
                  </Route>

                  <Route path="org/dashboard/*">
                    <Route path="" element={<AdminDashboard />} />
                    <Route path="details" element={<Details />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="endUser/*">
                    <Route path="endpoints" element={<Endpoints />} />
                    <Route path="endpointUsers" element={<EndpointUsers />} />
                    <Route path="connections" element={<MyConnections />} />
                    <Route path="assignedVcs" element={<AssignedVc />} />
                    <Route path="policies" element={<Policy />} />
                    <Route path="request" element={<Request />} />
                  </Route>
                </>
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default PrimaryLayout;
