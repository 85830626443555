import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Input,
  message,
  Cascader,
  Tag,
  Spin,
  Select,
} from "antd";
import { MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, Outlet } from "react-router-dom";
import { fetchEndpointGroups } from "../../../common/functions";
import AddGroupModal from "./AddEndpointGroup";
import AddUsertogroup from "./AddEpmUserGroup";
import EditEpmGroupModal from "./EditEndpointGroup";
import EpmGroupAuthflow from "./EpmGroupAuthflow";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";
const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
export default function EndpointGroups() {
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [endpointFilter, setEndpointFilter] = useState("");
  const [endpoints, setEndpoints] = useState([]);
  const [isAddgroup, setIsAddgroup] = useState(false);
  const [IsaddUsertogroup, setIsaddUsertogroup] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState(null);
  const [totalRecords, setTotalRecords] = useState(1);
  const [IsGroupauthflow, setIsGroupauthflow] = useState(false);
  const [isEditgroup, setIsEditgroup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [search, setSearch] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearchValue = useDebounce(searchQuery, 500);

  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const { Option } = Select;

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = endpointGroups
      .filter((endpointgroups) =>
        endpointgroups.groupName.toLowerCase().includes(value.toLowerCase())
      )
      .map((endpointgroups) => ({
        label: endpointgroups.groupName,
        value: endpointgroups.groupName,
      }));

    setOptions(filteredOptions);
  };

  const handleSelect = (value) => {
    setSearchQuery(value);
    setPaginationParams({ current: 1, pageSize: 10 });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRowKeys:", selectedRowKeys, "selectedRows: ", selectedRows);
      setSelectedRowKeys(selectedRowKeys, selectedRows);
    },
    onSelect: (e, ef) => {
      console.log(e, ef);
    },
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  useEffect(() => {
    fetchInstance();
  }, []);

  // useEffect(() => {
  //   setPaginationParams({ ...paginationParams, current: 1 });
  // }, [endpointFilter]);

  useEffect(() => {
    fetchEndpointGroup();
  }, [debouncedSearchValue, filteredValue, currentPage, paginationParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpointGroups([]);
    }
  };

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      token: AUTH_TOKEN(),
      search: searchQuery,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      orgId: ORG_ID(),
    };
    setLoadingData(true);
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
      console.log(endpointGroups);
      // setTotalRecords(res?.data?.totalCount);
      setLoadingData(false);
      setTotalCount(res?.data?.totalGroups);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (err) {
      setLoadingData(false);
      if (err?.response?.data?.message === "Invalid credentials" || err?.response?.status === 401) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };

  const deleteEndpointGroup = async (record) => {
    let id = [];
    id.push(Number(record.groupId));
    console.log(record);
    let data = {
      domainId: DOMAIN_ID(),
      id: id,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    let url = `${REACT_APP_PAM_API}/instanceGroup/deleteEndpointGroup`;
    try {
      await axios.delete(url, {
        data: data,
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });

      await fetchEndpointGroup();
      notification.open({
        type: "success",
        content: `Endpoint Group Deleted`,
      });
    } catch (err) {
      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
        notification.open({
          type: "error",
          content: `Credentials are invalid`,
        });
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: `Error Deleting Endpoint Group`,
        });
      }
    } finally {
      fetchEndpointGroup();
    }
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: searchQuery,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoints(res?.data?.instances);
      })
      // .then((res) => {
      //   setEndpoints(
      //     res.data.instances.map((instance) => ({
      //       value: instance.instanceId,
      //       hostName: instance.hostName,
      //       label: (
      //         <div>
      //           {instance.hostName} <b>{`(${instance.publicIp})`}</b>
      //         </div>
      //       ),
      //     }))
      //   );
      // })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) => {
    console.log(path, inputValue, "inputValue");
    return path.some((option) => {
      const label = option?.label?.props?.children[0]
        ? option.label.props.children[0].toLowerCase()
        : option.label.toLowerCase();
      return label.indexOf(inputValue.toLowerCase()) > -1;
    });
  };

  // const handleFilter = (_, data) => {
  //   console.log(data);
  //   setfilterBy(data ? data[0]?.value : "");
  //   setFilteredValue(data ? data[1]?.hostName : "");
  //   setPaginationParams({ current: 1, pageSize: 10 });
  // };

  const handleFilter = (value) => {
    if (value) {
      const selectedOption = endpoints.find(
        (option) => option.hostName === value || option.instanceId === value
      );

      if (selectedOption) {
        setfilterBy("endpoints");
        setFilteredValue(selectedOption.hostName || "");
      }
    } else {
      setfilterBy("");
      setFilteredValue("");
    }

    setPaginationParams({ current: 1, pageSize: 10 });
  };

  const option = [
    {
      value: "endpoints",
      label: "Endpoints",
      children: endpoints,
    },
  ];

  return (
    <Row className="content-conatiner">
      {setNotification}
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className="title">Endpoint Groups</h2>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={() => {
                setIsAddgroup(true);
              }}
            >
              Add Group
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                  <Col span={6}>
                    <p className="search-label">GroupName</p>
                    <div>
                      <AutoComplete
                        placeholder="Search GroupName"
                        allowClear
                        onSearch={handleSearch}
                        options={options}
                        onSelect={(value) => {
                          setSearch(value);
                        }}
                        onChange={(value) => {
                          setSearchQuery(value);
                        }}
                      />
                      {/* <Input
                        placeholder="Search name"
                        allowClear
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          if (paginationParams.current !== 1) {
                            setPaginationParams({ current: 1, pageSize: 10 });
                          }
                        }}
                      /> */}
                    </div>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Endpoints</p>

                    <Select
                      showSearch={filter}
                      allowClear
                      placeholder="Select Endpoint"
                      onChange={handleFilter}
                    >
                      {endpoints.map((user) => (
                        <Option value={user.hostName || user.instanceId}>
                          {user?.hostName} <b>{`(${user?.publicIp})`}</b>
                        </Option>
                      ))}
                    </Select>

                    {/* <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    /> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DataGrid
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        noResultsOverlay: CustomNoRowsOverlay,
                      }}
                      rows={endpointGroups}
                      paginationMode="server"
                      columns={[
                        {
                          headerName: "Group Name",
                          field: "groupName",
                          flex: 1,
                          renderCell: (val) => (
                            <Link
                              to={`/pam/endpointGroups/endpointGroupDetails`}
                              state={{ groupName: val.row.groupName, groupId: val.row.groupId }}
                              onClick={() => {
                                localStorage.removeItem("details");
                                let values = {
                                  groupName: val.row.groupName,
                                  groupId: val.row.groupId,
                                };
                                localStorage.setItem("details", JSON.stringify(values));
                              }}
                            >
                              {val.row.groupName}
                            </Link>
                          ),
                        },
                        {
                          field: "hostName",
                          headerName: "Endpoints",
                          key: "hostName",
                          flex: 1,
                          renderCell: (val) => (
                            <span>
                              <Tag color="blue">{val.row.hostName}</Tag>
                            </span>
                          ),
                        },
                        {
                          headerName: "User Count",
                          field: "usersCount",
                          dataIndex: "usersCount",
                          flex: 1,
                          renderCell: (val) => (
                            <Link
                              disabled={val.row.usersCount === 0 ? true : false}
                              to={`/pam/endpointGroups/endpointGroupDetails?selectedTab=endpointUsers`}
                              state={{ groupName: val.row.groupName, groupId: val.row.groupId }}
                              onClick={() => {
                                localStorage.removeItem("details");
                                let values = {
                                  groupName: val.row.groupName,
                                  groupId: val.row.groupId,
                                };
                                localStorage.setItem("details", JSON.stringify(values));
                              }}
                            >
                              {val.row.usersCount}
                            </Link>
                          ),
                        },
                        {
                          headerName: "Actions",
                          field: "action",
                          flex: 1,
                          renderCell: (val) => (
                            <Dropdown
                              placement="bottomLeft"
                              menu={{
                                items: [
                                  {
                                    key: "add-user",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(val.row);
                                          setIsaddUsertogroup(true);
                                        }}
                                      >
                                        Add User to Group
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "auth-flow",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(val.row);
                                          setIsGroupauthflow(true);
                                        }}
                                      >
                                        Auth Flow
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "edit-group",
                                    label: (
                                      <Link
                                        onClick={() => {
                                          setSelectedRecords(val.row);
                                          setIsEditgroup(true);
                                        }}
                                      >
                                        Edit Group
                                      </Link>
                                    ),
                                  },
                                  {
                                    key: "delete-group",
                                    label: (
                                      <Link
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          deleteEndpointGroup(val.row);
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    ),
                                  },
                                ],
                              }}
                            >
                              <Button type="text" shape="circle" icon={<MoreOutlined />} />
                            </Dropdown>
                          ),
                        },
                      ]}
                      rowCount={totalCount}
                      sortModel={sortModel}
                      onSortModelChange={handleSortModelChange}
                      style={{ border: "none" }}
                      loading={loadingData}
                      paginationModel={currentPageModel}
                      page={currentPage}
                      onPaginationModelChange={(params) => {
                        setCurrentPage(params.page);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Outlet />
      {isAddgroup && (
        <AddGroupModal
          isAddgroup={isAddgroup}
          setIsAddgroup={setIsAddgroup}
          fetchepmGroups={fetchEndpointGroups}
        />
      )}

      {IsaddUsertogroup && (
        <AddUsertogroup
          IsaddUsertogroup={IsaddUsertogroup}
          setIsaddUsertogroup={setIsaddUsertogroup}
          selectedRecords={selectedRecords}
        />
      )}

      {isEditgroup && (
        <EditEpmGroupModal
          isEditgroup={isEditgroup}
          setIsEditgroup={setIsEditgroup}
          selectedRecords={selectedRecords}
          endpoints={endpoints}
          fetchepmGroups={fetchEndpointGroup}
        />
      )}

      {IsGroupauthflow && (
        <EpmGroupAuthflow
          IsGroupauthflow={IsGroupauthflow}
          setIsGroupauthflow={setIsGroupauthflow}
          selectedRecords={selectedRecords}
        />
      )}
    </Row>
  );
}
