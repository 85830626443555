import React, { useEffect, useState } from "react";
import { Cascader, Col, Input, Row, Table, Tag, Typography, message } from "antd";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const ListSshKeys = ({ currentTab }) => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [policyData, setPolicyData] = useState([]);
  const [endpoint, setEndpoint] = useState([]);
  const [endpointGroup, setEndpointGroup] = useState([]);

  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState();
  const { Text } = Typography;

  const [options, setOptions] = useState([]);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [currentPage, setCurrentPage] = useState(0);

  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = policyData
      .filter((policydata) => policydata.policyName.toLowerCase().includes(value.toLowerCase()))
      .map((policydata) => ({
        label: policydata.policyName,
        value: policydata.policyName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchCredRotationPolicy();
  }, [paginationParams, currentPage, debouncedValue, filterBy, filteredValue, currentTab]);

  useEffect(() => {
    fetchInstance();
    fetchEndpointGroup();
  }, []);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const columns = [
    {
      headerName: "Policy Name",
      field: "policyName",
      key: "policyName",
    },
    {
      headerName: "Rotation Time Frame",
      field: "rotationInDays",
      key: "rotationInDays",
      width: 150,
      renderCell: (data) => (
        <span>
          {data.row.rotationInDays} {data.row.rotationInDays === 1 ? "day" : "days"}
        </span>
      ),
    },
    { headerName: "User Type", field: "userType", key: "userType" },
    {
      headerName: "Endpoints Impacted",
      field: "endpoints",
      key: "endpoints",
      minWidth: 160,
      renderCell: (tags) => (
        <span>
          {tags.row.endpoints.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      headerName: "Endpoint Groups",
      field: "endpointGroups",
      key: "endpointGroups",
      width: 150,
    },
    {
      headerName: "Last Modified",
      field: "lastModified",
      key: "lastModified",
      renderCell: (data) => {
        let time = moment(data.row.lastModified)
          .tz("America/Los_Angeles")
          .format("DD-MM-YYYY HH:mm [PST]");
        return time;
      },
    },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      renderCell: (val) => {
        let statusVal = val.row.status.toLowerCase() === "inactive" ? "Inactive" : "Active";
        return (
          <>
            <Text type={statusVal !== "Inactive" ? "success" : "danger"}> {statusVal}</Text>
          </>
        );
      },
    },
  ];

  const fetchCredRotationPolicy = (e) => {
    let requestData = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search,
      credentialType: "SSH",
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setTotalCount(res?.data?.totalCount);
        const newPolicyData = res?.data?.credentialPolicies?.map((data, index) => ({
          ...data,
          id: index + 1,
        }));
        console.log(policyData);
        setPolicyData(newPolicyData);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpoint(
          res?.data?.instances.map((ins) => ({
            value: ins.instanceId,
            label: (
              <div>
                {ins.hostName} <b>{`(${ins.publicIp})`}</b>
              </div>
            ),
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/instanceGroup/listEndpointGroup`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpointGroup(
          res?.data?.groups?.map((grp) => ({
            value: grp.groupId,
            label: grp.groupName,
          }))
        );
      })

      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints groups",
          });
        }
      });
  };
  const displayRender = (labels) => labels[labels.length - 1];
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    if (data && data[0]?.value === "ENDPOINT") {
      let endpoint = data[1].label.props.children[0];
      setFilteredValue(data ? endpoint : "");
    } else {
      setFilteredValue(data ? data[1]?.label : "");
    }
    setPaginationParams({ current: 1, pageSize: 10 });
  };
  const option = [
    {
      value: "ENDPOINT",
      label: "Endpoints",
      children: endpoint,
    },
    {
      value: "endpointGroup",
      label: "Endpoint Group",
      children: endpointGroup,
    },
    {
      value: "Status",
      label: "Status",
      children: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    },
  ];

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data.forEach((row) => {
        const value = row[col];
        const width = value ? value.toString().length * 13 : 0;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });

    return columnWidths;
  };

  // Calculate column widths
  const columnWidths = calculateColumnWidths(
    policyData,
    [
      "policyName",
      "rotationInDays",
      "userType",
      "endpoints",
      "endpointGroups",
      "lastModified",
      "status",
    ],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Cascader
                      options={option}
                      expandTrigger="hover"
                      displayRender={displayRender}
                      onChange={handleFilter}
                      placeholder="Search to select"
                      showSearch={{
                        filter,
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <AutoComplete
                      placeholder="Search Policy Name"
                      allowClear
                      onSearch={handleSearch}
                      options={options}
                      onChange={(value) => {
                        setSearch(value);
                      }}
                    />
                    {/* <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    /> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={policyData}
                columns={adjustedColumns}
                paginationMode="server"
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                loading={isLoading}
                paginationModel={currentPageModel}
                page={currentPage}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListSshKeys;
